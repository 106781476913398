.custom-table {
    word-break: break-word;
    .btn {
        word-break: normal
    }

    .caption {
        text-align: center;
        width: 100%;
        visibility: hidden;
    }
}
.cursor-hover:hover {
    cursor: pointer;
}
.table-row-hover:hover {
    cursor: default;
    background-color: rgba(0, 0, 0, .04);
}
.table-row-hover a:hover {
    text-decoration: none
}
.expanded-content {
    border-bottom: 1px solid var(--border-color) !important;
    background-color: rgba(0, 0, 0, .02);
}
.table-row-link:hover {
    text-decoration: none
}
.header-border-bottom {
    border-bottom: 1.5px solid rgba(85, 85, 85, 0.125)
}
.table-border-bottom {
    border-bottom: 1px solid rgba(85, 85, 85, 0.125)
}
