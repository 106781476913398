/* .footer-dk-gray {
    background: #2d2d2d;
    color: #fff;
}

.p-4 {
    padding: 1.5rem!important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


@media (min-width: 576px)
.col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
}


.pb-4, .py-4 {
    padding-bottom: 1.5rem!important;
}

@media (min-width: 576px)
.text-sm-left {
    text-align: left!important;
} */



.pb-4, .py-4 {
  padding-bottom: 0rem!important;
}

.cer certificate to aws acm.footer-dk-gray {
    background: #2d2d2d;
    color: #fff;
  }
  
  footer {
    font-size: 1rem;
  }
  
  footer a:hover {
      color: #c2c2c2
  }
  
  @media (min-width: 47.5em) {
    footer .osu-siteinfo-social {
      float: right;
    }
    footer .osu-siteinfo-social li {
      float: left;
    }
  }
  
  footer a,
  address,
  div,
  img,
  li,
  p,
  span,
  ul {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  
  footer img {
    max-width: 100%;
    height: auto;
  }
  
  footer .osu-logo {
    height: 40px;
  }
  
  footer p {
    margin-bottom: 0;
  }
  
  footer ul {
    list-style: none;
  }
  
  footer .osu-siteinfo-social {
    margin-top: 0;
    padding-left: 0;
    text-align: right;
  }
  
  footer .osu-siteinfo-social li {
    list-style-type: none;
    display: inline-block;
  }
  
  footer li .siteinfo-social-link {
    color: #fff;
    background-color: #666;
    margin: 0 0 1em 1em;
    width: 2.55em;
    padding: 0.62em 0 0.46em;
    text-align: center;
    display: block;
  }
  
  footer .element-invisible {
    width: 1px;
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
  }
  
  footer a {
    color: #ededed;
    font-weight: 400;
    text-decoration: underline;
  }
  
  .footer-text ul li {
    float: left;
    margin-right: 10px;
}

  @media only screen and (max-width:994px){
    .footer-text ul li {
      float: left;
      margin-right: 10px;
  }

  }
  